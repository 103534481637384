import Grid from '@mui/material/Unstable_Grid2';

import ResponseFilterForm from 'components/forms/responses/ResponseFilterForm';
import UploadResponsesForm from 'components/forms/responses/UploadResponsesForm';

const ActionsBar = () => {
  return (
    <Grid container columns={16}>
      <Grid sm={14}>
        <ResponseFilterForm />
      </Grid>

      <Grid display="flex" justifyContent="flex-end" alignItems="flex-end" sm={2}>
        <UploadResponsesForm />
      </Grid>
    </Grid>
  );
};

export default ActionsBar;
