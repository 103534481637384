import { FormEvent, useContext } from 'react';

import Grid from '@mui/material/Unstable_Grid2';

import { Form, Formik } from 'formik';

import {
  DatePickerGroup,
  LinkButton,
  SearchableMultiSelect,
  Select,
  SubmitButton,
  ButtonsBlock,
} from 'components/UIComponents';

import { BulkSelectContext } from 'context/BulkSelectContext';
import { FilterContext } from 'context/FilterContext';

import { getOutletOptions, getProductOptions, getResponseFileNameOptions } from 'helpers/collections';
import { nonEmptyValues } from 'helpers/utils';

import { statusOptions } from 'constants/responses';

import { IResponseFilterOptions } from 'interfaces/Response/IResponseFilterOptions';

import { controllWrapperStyles } from './styledComponents';

const emptyValues = {
  dateFrom: '',
  dateTo: '',
  isException: '',
  productId: [],
  outletId: [],
  fileId: [],
};

const ResponseFilterForm = () => {
  const { filterOptions, onFilter } = useContext(FilterContext);
  const { onChangeSelectAll } = useContext(BulkSelectContext);

  const initital = filterOptions as IResponseFilterOptions;

  const initialValues = {
    dateFrom: initital.dateFrom || '',
    dateTo: initital.dateTo || '',
    isException: initital.isException || '',
    productId: initital.productId || [],
    outletId: initital.outletId || [],
    fileId: initital.fileId || [],
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={(values) => {
        onChangeSelectAll(false);
        const present = nonEmptyValues(values);
        onFilter(present);
      }}
    >
      {({ values, handleSubmit, resetForm }) => {
        return (
          <Form>
            <Grid container columns={15} columnSpacing={{ xs: 2, lg: 3 }}>
              <Grid xs={4}>
                <DatePickerGroup
                  startProps={{
                    name: 'dateFrom',
                    direction: 'column',
                    maxDate: values.dateTo,
                    placeholder: 'Any Date',
                  }}
                  endProps={{
                    name: 'dateTo',
                    direction: 'column',
                    minDate: values.dateFrom,
                    placeholder: 'Any Date',
                  }}
                  label="Response Date"
                  connector="to"
                  styles={controllWrapperStyles}
                  direction="column"
                />
              </Grid>
              <Grid xs={2}>
                <SearchableMultiSelect
                  name="productId"
                  label="Product"
                  initial={initialValues.productId}
                  getOptionsList={getProductOptions}
                  styles={controllWrapperStyles}
                  direction="column"
                />
              </Grid>
              <Grid xs={2}>
                <SearchableMultiSelect
                  name="outletId"
                  label="Outlet"
                  initial={initialValues.outletId}
                  getOptionsList={getOutletOptions}
                  styles={controllWrapperStyles}
                  direction="column"
                />
              </Grid>
              <Grid xs={2}>
                <Select
                  name="isException"
                  placeholder="Any Status"
                  label="Status"
                  options={statusOptions}
                  styles={controllWrapperStyles}
                  direction="column"
                  emptyOptionLabel="Any Status"
                />
              </Grid>
              <Grid xs={2}>
                <SearchableMultiSelect
                  name="fileId"
                  label="File"
                  initial={initialValues.fileId}
                  getOptionsList={getResponseFileNameOptions}
                  styles={controllWrapperStyles}
                  direction="column"
                />
              </Grid>
              <Grid display="flex" alignItems="flex-end" xs={3}>
                <ButtonsBlock>
                  <LinkButton
                    onClick={() => {
                      resetForm({ values: emptyValues });
                      onChangeSelectAll(false);
                      onFilter({});
                    }}
                  >
                    Clear filter
                  </LinkButton>
                  <SubmitButton onClick={(values: FormEvent<HTMLFormElement>) => handleSubmit(values)}>
                    Apply Filter
                  </SubmitButton>
                </ButtonsBlock>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ResponseFilterForm;
